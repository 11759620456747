<template>
  <div class="radio-button-container">
    <input
      v-show="false"
      :ref="checkboxId" type="checkbox"
      :id="checkboxId"
      v-model="checked"
      @change="handleChange"
    >
    <!-- :value="inputValue" -->
    <div class="checkbox_group" @click="clickCheckbox">
      <div class="checkmark_square" :class="{ activated : value }">
        <div class="checkmark_fill" :class="{ activated : value }">
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.333 5.667 4 8.333l6.667-6.666" stroke="#1A1A1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <label v-if='label' class="label" style="cursor:pointer" :for="checkboxId">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    checkboxId: {
      type: String,
      required: true,
    },
    // inputValue: { required: true },
    value: { required: true },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checked: this.value,
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.checked)
    },
    clickCheckbox() {
      this.$refs[this.checkboxId].click()
    }
  },
  created () {
    this.$watch('value', newValue => {
      this.checked = newValue
    })
  },
}
</script>

<style lang="scss" scoped>
.radio-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 12px;
  cursor: pointer;

  &:hover .checkbox_group { background-color: rgba(26, 26, 26, 0.1) }
  .checkbox_group {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    position: relative;
    padding: 12px;
    margin: -12px;
    border-radius: 100%;
    &:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &:active {
      background-color: rgba(26, 26, 26, 0.2);
    }

    .checkmark_square {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border: 2px solid #737373;
      border-radius: 4px;
      &.activated {
        border-color: #1A1A1A;
      }
    }
    .checkmark_fill {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin: auto;
      position: absolute;
      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);

      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      &.activated {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #1A1A1A;
  }
}
</style>