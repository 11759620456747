<template>
  <div style='position: relative'>
    <div class="favorite-list-item" :class='{"select-hover": isOrdering}' @click="handleInput(contact.contactId)">
      <div v-if='isOrdering' style='padding-left: 4px; display: flex;'>
        <CustomRadioButton
          :radioId='"id" + contact.contactId'
          :inputValue='contact.contactId'
          v-bind:value='contactSelectedId'
          v-on:input='handleInput'
          label=' '
        />
      </div>
      <img v-else-if='contact.tipoContactoId === 1' src='~@/assets/img/icon/profile-light-outline.svg'>
      <img v-else-if='contact.tipoContactoId === 2' src='~@/assets/img/icon/building-office-6.svg'>
      <div class='registerName-and-button' :class='{ notLastEl: contactList.length !== elKey + 1 }'>
        <div style='flex: 1 1 0; display: flex; flex-direction: column; gap: 4px;'>
          <div v-if="contact.esPredeterminado && !reference.includes('destination')">
            <div class='text-xs-bold register-predetermined'>Remitente predeterminado</div>
          </div>
          <div class='text-m-regular'>{{ contact.nombreContacto }}</div>
        </div>
        <div class='dropdown-button' @click='toggleOpen()'>
          <img src='~@/assets/img/icon/menu_1.svg'>
        </div>
      </div>
    </div>
    <div class='menu_drop_down' v-if='isOpenDropDown' ref='dropdown'>
      <div style='border-bottom: solid 1px #D0D0D0' class='option' @click='handleEdit()'>
        <img src='~@/assets/img/icon/edit-square.svg'>
        <div>Editar</div>
      </div>
      <div class='option' @click='handleDelete()'>
        <img src='~@/assets/img/icon/delete.svg'>
        <div style='color: #CC454A'>Eliminar</div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomRadioButton from '../Form/CustomRadioButton.vue'

  export default {
    components: {
      CustomRadioButton,
    },
    props: {
      isOrdering: {
        type: Boolean,
        default: false
      },
      contactSelectedId: {
        type: Number,
        default: 0
      },
      contact: {
        type: Object,
        default: {}
      },
      elKey: {
        type: Number
      },
      contactList: {
        type: Array,
        default: []
      },

      reference: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isOpenDropDown: false
      }
    },
    methods: {
      handleInput(e) {
        if(this.isOrdering){
          this.$emit('handleSelectContact', e)
        }
      },
      toggleOpen() {
        setTimeout(() => {
          this.isOpenDropDown = true
        }, 50)
      },
      handleEdit() {
        this.$emit('editAction', this.contact)
      },
      handleDelete() {
        this.$emit('deleteAction', this.contact)
      },
      handleClickOutside(event) {
        const dropdown = this.$refs.dropdown
        if (dropdown && !dropdown.contains(event.target) && this.isOpenDropDown) {
          this.isOpenDropDown = false
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside)
    },
  }
</script>

<style lang='scss'>
  .registerName-and-button {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: flex;

    &.notLastEl {
      border-bottom: solid 1px #D0D0D0;
    }

    .register-predetermined {
      width: fit-content;
      padding: 4px 8px;
      border-radius: 360px;
      border: 1px solid #FF7177;
      color: #FF7177
    }
  }

  .dropdown-button {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 12px;
    transition: .3s;
    cursor: pointer;
  }
  .dropdown-button:hover {
    background: rgba(0, 0, 0, 0.16);
  }
  .menu_drop_down {
    margin-top: -10px;
    position: absolute;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 16px -4px rgba(0, 0, 0, 0.20);
    border-radius: 16px;
    z-index: 99;
    background-color: white;
    overflow: hidden;
    right: 0;

    .option {
      padding: 12px 20px;
      display: flex;
      gap: 16px;
      cursor: pointer;
      transition: .3s;

      &:hover {
        background-color: #e6e6e6;
      }
    }


  }

  .favorite-list-item {
    display: flex;

    &.select-hover {
      cursor: pointer;   
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

</style>