<template>
  <div class="label-and-select-container">
    <label class="label_input">{{ label }}</label>
    <div @click="openModal" class="select-button" :class="{ notSelectedAfterBlur: notSelectedAfterBlur }">
      <span v-if="!selectedOptionsCount" class="select-button__placeholder">{{ placeholder }}</span>
      <span v-else class="select-button__value">{{ selectedOptionsCount }}</span>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m5 7.5 5 5 5-5" stroke="#737373" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <ErrorMessage
      v-if="notSelectedAfterBlur"
      message="Selecciona la(s) categoría(s) de tu negocio."
    />

    <div class="categories__selected-container" v-if="wordsToShow.length > 0">
      <div class="word-wrapped" v-for="word in wordsToShow" :key="word.id">
        <span>{{ word.name }}</span>
        <div style="display: flex;" @click="handleCheckboxChange(word)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"><path d="M6.114 9.886 8 8m1.885-1.886L8 8m0 0L6.114 6.114M8 8l1.885 1.886M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
        </div>
      </div>
      <div v-if="missingWordsCount >= 2" class="show-more-less-container" @click="toggleSeeMore">
        <span>{{ seeMore ? 'Ver menos ' : 'Ver más ' }}</span>
        <div v-if="seeMore" style="display: flex">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#FF565D" stroke-linecap="round" stroke-linejoin="round"><path d="M5.333 8h5.334M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
        </div>
        <div v-else class="indicator-number">
          {{ missingWordsCount }}
        </div>
      </div>
    </div>

    <div id="modalBusinessCategory" class="modal-business-category modal">
      <div class="modal__header">
        <InputText
          isForSearch
          placeholder="Busca una categoría"
          v-model="wordToSearch"
        />
        <div class="close-modal-button" @click="closeModal">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.757 17.243 12 12m5.243-5.243L12 12m0 0L6.757 6.757M12 12l5.243 5.243" stroke="#1A1A1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
      <div class="modal__body">
        <div class="categories__selected-container" v-if="wordsToShow.length > 0">
          <div class="word-wrapped" v-for="word in wordsToShow" :key="word.id">
            <span>{{ word.name }}</span>
            <div style="display: flex;" @click="handleCheckboxChange(word)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"><path d="M6.114 9.886 8 8m1.885-1.886L8 8m0 0L6.114 6.114M8 8l1.885 1.886M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
            </div>
          </div>
          <div v-if="missingWordsCount >= 2" class="show-more-less-container" @click="toggleSeeMore">
            <span>{{ seeMore ? 'Ver menos ' : 'Ver más ' }}</span>
            <div v-if="seeMore" style="display: flex">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" stroke="#FF565D" stroke-linecap="round" stroke-linejoin="round"><path d="M5.333 8h5.334M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs></svg>
            </div>
            <div v-else class="indicator-number">
              {{ missingWordsCount }}
            </div>
          </div>
        </div>
        <div class="categories__list" v-if="!wordToSearch">
          <div class="category__drop-down" v-for="category in categoriesListCopy" :key="category.id">
            <div class="name_container" @click="extendCategory(category)">
              <div class="category__name">{{ category.category_name }}</div>
              <div style="display: flex;">
                <svg :class="{ 'rotated-svg': category.is_expanded }" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="m5 7.5 5 5 5-5" stroke="#737373" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
            <div class="sub-categories-list" :class="{ 'expanded': category.is_expanded}">
              <div v-for="sub_category in category.sub_categories" :key="sub_category.id">
                <CustomMultiCheckbox
                  v-if="!sub_category.is_other_category"
                  :value="sub_category"
                  :label="sub_category.name"
                  :selectedOptions="subCategoriesSelected"
                  @change="handleCheckboxChange"
                />
                <div v-else class="other_category">
                  <CustomMultiCheckbox
                    :value="sub_category"
                    :selectedOptions="subCategoriesSelected"
                    @change="handleCheckboxChange"
                  />
                  <span>Otro:</span>
                  <input
                    style="height: auto !important; margin: 0 !important;" type="text"
                    v-model="sub_category.name"
                    @input="handleOtherCategoryDescription(sub_category)"
                  >
                      <!-- :disabled="!subCategoriesSelected.includes(sub_category)" -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="categories_filtered" v-else>
          <div class="filtered-results-count">
            {{ categoriesListFiltered.length === 0 ? `No hay resultados para “${wordToSearch}”.` : `${categoriesListFiltered.length} ${categoriesListFiltered.length === 1 ? 'resultado' : 'resultados'} para “${wordToSearch}”.` }}
          </div>
          <div class="filtered-results">
            <CustomMultiCheckbox
              v-for="sub_category in categoriesListFiltered" :key="sub_category.id"
              :value="sub_category"
              :label="sub_category.name"
              :selectedOptions="subCategoriesSelected"
              @change="handleCheckboxChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api2 } from '../../services/api'
import ErrorMessage from '../ErrorMessage.vue'
import InputText from './InputText.vue'
import CustomMultiCheckbox from "./CustomMultiCheckbox.vue"

export default {
  components: {
    ErrorMessage, InputText, CustomMultiCheckbox
  },
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      required: true
    },
    showEmptyInputError: {
      type: Boolean,
      default: false
    },
    categoriesList: {
      default: []
    }
  },
  data() {
    return {
      seeMore: false,
      notSelectedAfterBlur: false,
      wordToSearch: '',
      categoriesListCopy: [],
      subCategoriesSelected: []
    }
  },
  methods: {
    toggleSeeMore() {
      this.seeMore = !this.seeMore
    },
    extendCategory(categoryClicked) {
      this.categoriesListCopy = this.categoriesList.map(category => ({
        ...category,
        is_expanded: categoryClicked.id === category.id ? !categoryClicked.is_expanded : false
        // is_expanded: categoryClicked.id === category.id ? !categoryClicked.is_expanded : category.is_expanded
      }))
    },
    openModal() {
      const modal = document.querySelector("#modalBusinessCategory")
      const instance = M.Modal.init(modal, {
        dismissible: true
      })
      if (instance) instance.open()
    },
    closeModal() {
      const modal = document.querySelector('#modalBusinessCategory')
      const instance = M.Modal.getInstance(modal)
      instance.close()
    },
    handleCheckboxChange(value) {
      if (this.subCategoriesSelected.includes(value)) {
        const index = this.subCategoriesSelected.indexOf(value)
        this.subCategoriesSelected.splice(index, 1)
      } else {
        this.subCategoriesSelected.push(value)
      }
      this.notSelectedAfterBlur = this.subCategoriesSelected === 0
      this.$emit('input', this.subCategoriesSelected)
    },

    setCategoriesSelected(categories) {
      this.subCategoriesSelected = categories
    },
    handleOtherCategoryDescription(category) {
      if (category.name.length === 0 && this.subCategoriesSelected.includes(category)) {
        const index = this.subCategoriesSelected.indexOf(category)
        this.subCategoriesSelected.splice(index, 1)
      } else if(category.name.length > 0 && !this.subCategoriesSelected.includes(category)) {
        this.subCategoriesSelected.push(category)
      }
      this.notSelectedAfterBlur = this.subCategoriesSelected === 0
      this.$emit('input', this.subCategoriesSelected)
    },
    resetSelectedCategories() {
      this.subCategoriesSelected = []
    },

    normalize(text) {
      return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLocaleLowerCase();
    }
  },
  computed: {
    categoriesListFiltered() {
      if (!this.wordToSearch) return []
      const subcategoriesFiltered = []
      this.categoriesList.forEach(category => {
        const filtered = category.sub_categories.filter(subCategory => {
          const keywords = subCategory.key_words.map(word => this.normalize(word))
          const searchWord = this.normalize(this.wordToSearch)
          return keywords.some(keyword => keyword.includes(searchWord))
        })
        if (filtered.length > 0) subcategoriesFiltered.push(...filtered)
      })
      return subcategoriesFiltered
    },
    selectedOptionsCount() {
      const count = this.subCategoriesSelected.length
      if (count === 1) return '1 seleccionada'
      if (count > 1) return `${count} seleccionadas`
      return ''
    },
    wordsToShow() {
      const count = this.subCategoriesSelected.length
      if(count >= 7 && !this.seeMore) return this.subCategoriesSelected.slice(0, 5)
      return this.subCategoriesSelected
    },
    missingWordsCount() {
      return this.subCategoriesSelected.length - 5
    },
  },
  created () {
    this.$watch('showEmptyInputError', value => {
      if(value) {
        this.notSelectedAfterBlur = true
      }
    })
    this.getCategories()
  },
  watch: {
    categoriesList(newValue) {
      this.categoriesListCopy = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
.label-and-select-container {
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  .label_input {
    text-align: left;
    color: #1A1A1A;
    font-size: 14px;
    line-height: 20px;
  }
  .select-button {
    cursor: pointer;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid #737373;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    &.notSelectedAfterBlur { border: 1px solid #E50000 }

    .select-button__placeholder { color: #737373 }
    .select-button__value { color: #1A1A1A }
  }
}

.categories__selected-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  // padding: 14px 16px;
  .word-wrapped {
    margin: 12px 0;
    cursor: default;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #1A1A1A;
    border-radius: 360px;
    display: flex;
    padding: 4px 4px 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    transition: .3s;
    &:hover { background: rgba(26, 26, 26, 0.80) }
    &:active { background: rgba(26, 26, 26, 0.50) }
    div { cursor: pointer }
  }
  .show-more-less-container {
    margin: 12px 0;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    cursor: pointer;
    &:hover span { text-decoration: underline; color: #CC454A }
    &:hover .indicator-number { background: #CC454A }
    &:hover div path { stroke: #CC454A }
    span {
      transition: .3s;
      color: #FF565D;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .indicator-number {
      display: flex;
      height: 16px;
      padding: 0px 4.5px;
      justify-content: center;
      align-items: center;
      background: #FF565D;
      border-radius: 50%;

      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.modal-business-category {
  background-color: #ffffff;
  width: 450px !important;
  max-width: 100vw;
  top: 0 !important;
  bottom: 0;
  border-radius: 24px;
  .modal__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 16px;
    box-shadow: 0px 2px 4px 0px rgba(26, 26, 26, 0.04);
    .close-modal-button {
      cursor: pointer;
      display: flex;
      padding: 12px;
      margin: -12px;
      transition: .3s;
      border-radius: 50%;
      &:hover { background-color: rgba(26, 26, 26, 0.10) }
      &:active { background-color: rgba(26, 26, 26, 0.20) }
    }
  }

  .modal__body {
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    height: 80vh;
    .categories__selected-container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 8px;
      padding: 14px 16px;
      .word-wrapped {
        margin: 12px 0;
        cursor: default;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #1A1A1A;
        border-radius: 360px;
        display: flex;
        padding: 4px 4px 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        transition: .3s;
        &:hover { background: rgba(26, 26, 26, 0.80) }
        &:active { background: rgba(26, 26, 26, 0.50) }
        div { cursor: pointer }
      }
      .show-more-less-container {
        margin: 12px 0;
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;
        cursor: pointer;
        &:hover span { text-decoration: underline; color: #CC454A }
        &:hover .indicator-number { background: #CC454A }
        &:hover div path { stroke: #CC454A }
        span {
          transition: .3s;
          color: #FF565D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .indicator-number {
          display: flex;
          height: 16px;
          padding: 0px 4.5px;
          justify-content: center;
          align-items: center;
          background: #FF565D;
          border-radius: 50%;

          color: #FFF;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
    .categories__list {
      display: flex;
      flex-direction: column;
      .category__drop-down {
        &:not(:last-child) { border-bottom: 1px solid #E6E6E6 }
        .name_container{
          cursor: pointer;
          display: flex;
          padding: 12px 16px;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          transition: .3s;
          &:hover { background: rgba(26, 26, 26, 0.10) }
          &:active { background: rgba(26, 26, 26, 0.20) }
          .category__name {
            color: #1A1A1A;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
          div svg { transform: rotate(0deg); transition: transform .2s ease-in-out }
          div .rotated-svg { transform: rotate(180deg) }
        }
        .sub-categories-list {
          opacity: 0;
          height: 0;
          transition: opacity .3s ease-in-out;
          overflow: hidden;
          &.expanded {
            opacity: 1;
            height: auto;

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            padding: 12px 16px;
            background: #FAFAFA;
          }
          .other_category {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }
      }
    }
    .categories_filtered {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .filtered-results-count {
        background: #FAFAFA;
        display: flex;
        padding: 12px 16px;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        color: #1A1A1A;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .filtered-results {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 12px 16px;
      }
    }

    &::-webkit-scrollbar { width: 15px; }
    &::-webkit-scrollbar-thumb {
      background: rgba(26, 26, 26, 0.5);
      background-clip: content-box;
      border: 5px solid transparent;
      border-radius: 15px;
    }
  }
}


@media (max-width: 600px) {
  .modal-business-category {
    width: 100% !important;
    bottom: 0 !important;
    left: 0 !important;
    top: auto !important;
    border-radius: 24px 24px 0 0;
    .modal__body { height: 70vh }
  }
}
</style>