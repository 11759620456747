<template>
  <div class="attribute">
    <label class="custom-input-label">{{ label }}</label>
    <div class="input-select-group with-sufix" :class="{ attributeIsMissing: attributeIsMissing, 'focused': attributeisFocused, isCurrency: isCurrency}">
      <input
        class="input-primary input_atribute"
        type="number"
        step="0.01"
        placeholder="00.00"
        @focus="attributeFocused"
        @blur="attributeBlurred"
        @keyup.enter="blurInput($event)"
        v-model.number="packageAttributeDisplay"
      >
      <span>{{ measure }}</span>
    </div>
    <ErrorMessage
      :message="missingErrorMessage"
      v-show="attributeIsMissing"
    />
    <ErrorMessage
      :message="maxLengthErrorMessage"
      v-show="max > 0 && value > max"
    />
  </div>
</template>

<script>
import ErrorMessage from '../ErrorMessage.vue'

export default {
  components: {
    ErrorMessage,
  },
  props: {
    label: String,
    measure: String,
    value: [Number, String],
    missingErrorMessage: {
      type: String,
      default: 'Error'
    },
    showEmptyInputError: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 0
    },
    isCurrency: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      attributeIsMissing: false,
      attributeisFocused: false
    }
  },
  methods: {
    attributeFocused() {
      this.attributeisFocused = true
    },
    attributeBlurred() {
      this.attributeisFocused = false
      this.attributeIsMissing = !this.value
    },
    blurInput(event) {
      event.target.blur()
    }
  },
  computed: {
    packageAttributeDisplay: {
      get() {
        return this.value ? this.value.toFixed(2) : ''
      },
      set(value) {
        let val = value
        if (value < 0) { val = value * (-1) }
        this.$emit('input', val)
      },
    },
    maxLengthErrorMessage() {
      return `La longitud máxima es ${this.max} cm.`
    }
  },
  created () {
    this.$watch('value', newValue => {
      if(newValue) {
        this.attributeIsMissing = false
      }
    })
    this.$watch('showEmptyInputError', value => {
      if(value) {
        this.attributeIsMissing = true
      }
    })
  },
}
</script>

<style scoped>
.custom-input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
}
.input-select-group {
  display: flex;
  gap: 8px;
  align-items: baseline;
  padding: 0 16px;
  flex-direction: row;
  min-height: 48px;
  margin-top: 8px;
  border: 1px solid #737373;
  border-radius: 8px;
}
.input-select-group:hover {
  border: 1px solid #1A1A1A;
}
.input-select-group.focused { border: 2px solid #1A1A1A }
.input-select-group.isCurrency { flex-direction: row-reverse }

.with-sufix {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.attributeIsMissing { border: 1px solid #E50000 }
.with-sufix span {
  padding-right: 0;
  color: #737373;
}
.input-primary,
.input-primary:focus {
  border-bottom: none !important;
}
.input-primary {
  padding: 0 !important;
  margin: 0 !important;
}
.input-primary::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}
.input-primary:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  padding-bottom: 0px!important;
}

.input_atribute::-webkit-inner-spin-button, 
.input_atribute::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.input_atribute { -moz-appearance:textfield; }
</style>