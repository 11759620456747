<template>
  <div class="radio-button-container" :class="{ disabled }">
    <input
      v-show="false"
      :ref="radioId" type="radio"
      :id="radioId"
      :value="inputValue"
      v-model="selected"
      @change="handleChange"
      :disabled="disabled"
    >
    <div class="radio-button_group" @click="activateRadio">
      <div class="checkmark_circle" :class="{ activated : inputValue === value }"></div>
      <div class="checkmark_fill" :class="{ activated : inputValue === value }"></div>
    </div>
    <div style='display: flex; flex-direction: column' @click="activateRadio">
      <span class='text-m-regular label'>{{ label }}</span>
      <span v-if='helperText' class='text-xs-regular label'>{{ helperText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    radioId: {
      type: String,
      required: true,
    },
    inputValue: { required: true },
    value: { required: true },
    label: {
      type: String,
      required: true,
    },
    helperText: {
      type: String,
      required: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    }
  },
  methods: {
    handleChange() {
      if (this.disabled) return
      this.$emit('input', this.selected)
    },
    activateRadio() {
      if (this.disabled) return
      this.$refs[this.radioId].click()
    }
  },
  created () {
    this.$watch('value', newValue => {
      this.selected = newValue
    })
  },
}
</script>

<style lang="scss" scoped>
.radio-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  opacity: 1;
  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;

    .radio-button_group {
      &:hover {
        background-color: transparent;
      }
    }
    .label {
      color: #737373;
    }
  }

  .radio-button_group {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s;
    border-radius: 100%;
    &:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &:active {
      background-color: rgba(26, 26, 26, 0.2);
    }

    .checkmark_circle {
      position: relative;
      height: 24px;
      width: 24px;
      border: 2px solid #737373;
      border-radius: 100%;
      &.activated {
        border-color: #1A1A1A;
      }
    }
    .checkmark_fill {
      margin: auto;
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 100%;
      background-color: #1A1A1A;

      opacity: 0;
      transform: scale(0);
      -webkit-transform: scale(0);

      transition: all 0.2s ease;
      -webkit-transition: all 0.2s ease;
      &.activated {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}
</style>