<template>
  <div class="form_section_title">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang="scss" scoped>
.form_section_title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: #1A1A1A;
}
</style>