<template>
  <div class="label__search_container">
    <label class="label_input_search">{{ label }}</label>
    <div>
      <div class="custom-select">
        <div class="selected" :class="{ open: open, notSelectedBeforeBlur: notSelectedBeforeBlur }">
          <span v-if="!open && !textToShow" class="custom-select-placeholder">{{ placeholder }}</span>
          <span v-if="!open">{{ textToShow }}</span>
          <input v-else ref="myInput" type="text" class="input_search" v-model.trim="textToFind">
          <RingLoader v-if="isLoading" />
        </div>
        <div class="items" :class="{ selectHide: !open }">

          <div v-if="options.length > 0" class="items_list">
            <div
              v-for="(option, i) of options"
              :key="i"
              @click="onClickElement(option)"
              :id="`div_${option.id}`"
            >
              <div v-if="isPoint">
                <div class="point_descs item_option">
                  <div class="point_desc_1">{{ option.district }}</div>
                  <div>
                    <div class="point_desc_2">{{ option.name }}</div>
                    <div class="point_desc_3">{{ option.address }}</div>
                  </div>
                </div>
              </div>
              <div v-if="!isPoint" class="item_option">
                <div>
                  {{ option.text }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="options.length === 0 && textToFind">
            <div style="padding-left: 15px;">
              {{ isLoading ? 'Buscando resultados...' : 'No hay resultados.' }}
            </div>
          </div>

        </div>
      </div>
      <ErrorMessage :message="errorMessage" v-if="notSelectedBeforeBlur && isRequired"/>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../ErrorMessage.vue'
import RingLoader from '../Loaders/RingLoader.vue'

// esto es para que se ejecute una funcion luego de un tiempo establecido
function debounce(func, wait) {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export default {
  props: {
    value: {
      // type: Number,
      required: true
    },
    repoSearch: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: 'Ingrese valor'
    },
    isPoint: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "Error"
    },
    showEmptyInputError: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
  },
  components: {
    ErrorMessage,
    RingLoader,
  },
  data() {
    return {
      selected: null,
      open: false,
      textToFind: '',
      textToShow: '',
      options: [],
      isClickedInside: false,
      notSelectedBeforeBlur: false,
      isLoading: false,

      idSelectedFromOutside: ''
    };
  },
  methods: {
    init() {
      this.selected = this.options.find(option => option.id === this.value)
      if(this.isPoint) {
        this.textToShow = this.selected?.labelAfterSelect || ''
      } else {
        this.textToShow = this.selected?.text || ''
      }
    },
    onClickElement(option) {
      setTimeout(() => {
        this.open = false
      }, 50)
      this.notSelectedBeforeBlur = false
      this.$emit('input', option.id)
      this.$emit('setSelectedDisctrict', { ...option, textToFind: this.textToFind })
      this.init()
    },
    handleClickOutside(event) {
      // Para checar si se hizo click dentro o fuera de todo esto
      const clickedComponent = event.target
      const isClickedInside = this.$el.contains(clickedComponent)
      this.isClickedInside = isClickedInside
      if(this.open && !isClickedInside && !this.textToFind) {
        this.init()
      }

      if(this.open && !isClickedInside && !this.textToShow) this.notSelectedBeforeBlur = true
      if(this.open && !isClickedInside && this.textToShow) this.notSelectedBeforeBlur = false

      this.open = isClickedInside
    },
    selectElementFromOutside(option) {
      this.idSelectedFromOutside = option.id
      this.textToFind = option.textToFinded
      this.$emit('input', option.id)
    },
  },
  created () {
    this.init()
    this.$watch('value', newValue => {
      this.selected = this.options.find(option => option.id === newValue)
      if(this.isPoint) {
        this.textToShow = this.selected?.labelAfterSelect || ''
      } else {
        this.textToShow = this.selected?.text || ''
      }
    })
    this.$watch('open', newValue => {
      if(newValue) {
        this.$nextTick(() => {
          this.$refs.myInput.focus();
        })
      }
    })
    this.$watch('textToFind', debounce(async text => {
      this.options = []
      if(!text) {
        this.$emit('input', '')
        this.$emit('setSelectedDisctrict', {})
        return
      }
      this.isLoading = true
      try {
        const data = await this.repoSearch(text)
        this.options = data
        if (this.idSelectedFromOutside) {
          setTimeout(() => {
            const div = document.querySelector(`#div_${this.idSelectedFromOutside}`)
            div.click()
            this.idSelectedFromOutside = ''
          }, 200)
        }
        this.init()
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
      // Cada 300ms hace la busqueda
    }, 300))
    this.$watch('showEmptyInputError', value => {
      if(value) {
        this.notSelectedBeforeBlur = true
      }
    })
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};

</script>
<style scoped>
.label__search_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.label_input_search {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #1A1A1A !important;
}
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
}

.custom-select .selected {
  padding: 0 16px;
  cursor: pointer;
  user-select: none;
  min-height: 50px;
  border: 1px solid #737373;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-select .selected:hover {
  border: 1px solid #1A1A1A;
}

.custom-select .selected.open { border: 2px solid #1A1A1A }
.custom-select .selected.notSelectedBeforeBlur { border: 1px solid #E50000 }

/* .custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #5f5f5f transparent transparent transparent;
} */

.custom-select-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}
.input_search {
  border-bottom: none !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.custom-select .items {
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 250px;
  overflow: overlay; /* para el scroll sea sobre las coasas */
  margin-top: 5px;
  line-height: 47px;
}
.item_option {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}
.custom-select .items .items_list > div:not(:last-child) {
  border-bottom: 1px solid #DEDEDE;
}
.item_option:hover { background: rgba(0, 0, 0, 0.05) }
.item_option:active { background: rgba(0, 0, 0, 0.15) }

.point_descs {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.point_desc_1 {
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}
.point_desc_2 {
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1A1A1A;
}
.point_desc_3 {
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1A1A1A;
}

.selectHide {
  display: none;
}

.custom-select .items::-webkit-scrollbar {
  width: 15px;
}
.custom-select .items::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.5);
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 15px;
}
</style>