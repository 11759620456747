<template>
  <div class="label-and-input">
    <label v-if="label" class="custom-input-label">{{ label }}</label>
    <div class="">
      <div class="input-select-group" :class="{ attributeIsMissing: missingErrorMessage && attributeIsMissing && !isOptional, 'focused': attributeisFocused, disabled: disabled }">
        <div v-if="isForSearch" class="input-for-search">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.167 14.166 17.5 17.5m-15-8.333a6.667 6.667 0 1 0 13.333 0 6.667 6.667 0 0 0-13.333 0Z" stroke="#737373" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <input
            class="input-primary"
            :placeholder="placeholder"
            @focus="attributeFocused"
            @blur="attributeBlurred"
            @keyup.enter="blurInput($event)"
            v-model="valueDisplay"
          >
          <div v-if="valueDisplay !== ''" class="erase__text" @click="valueDisplay = ''">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="10" fill="#737373"/>
              <path d="M6.505 13.495 10 10m3.495-3.495L10 10m0 0L6.505 6.505M10 10l3.495 3.495" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div v-else-if="password" class="input-for-password">
          <input
            :type="showPassword ? 'text' : 'password'"
            :autocomplete="autocomplete"
            class="input-primary"
            :placeholder="placeholder"
            @focus="attributeFocused"
            @blur="attributeBlurred"
            @keyup.enter="blurInput($event)"
            v-model="valueDisplay"
          >
          <div class="eye-icon" @click="showPassword = !showPassword">
            <svg v-if="showPassword" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 11.667a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334Z" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.5 10c-1.574 2.492-4.401 5-7.5 5s-5.926-2.508-7.5-5C4.415 7.632 6.66 5 10 5s5.585 2.632 7.5 5Z" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="m2.5 2.5 15 15M8.75 8.898a1.667 1.667 0 0 0 2.352 2.352" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6.135 6.3C4.733 7.284 3.565 8.683 2.5 10c1.574 2.492 4.401 5 7.5 5 1.292 0 2.536-.436 3.662-1.124M10 5c3.34 0 5.585 2.632 7.5 5-.265.42-.566.841-.898 1.25" stroke="#1A1A1A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <input
          v-else-if="!isTextArea"
          :type="inputType"
          :autocomplete="autocomplete"
          class="input-primary"
          :placeholder="placeholder"
          @focus="attributeFocused"
          @blur="attributeBlurred"
          @keyup.enter="blurInput($event)"
          v-model="valueDisplay"
          :readonly='disabled'
        >
        <textarea
          v-else
          class="textarea-primary"
          @focus="attributeFocused"
          @blur="attributeBlurred"
          :placeholder="placeholder"
          v-model="valueDisplay"
        ></textarea>
      </div>
      <ErrorMessage
        :message="missingErrorMessage"
        v-if="showMissingErrorMessage"
      />
      <ErrorMessage
        :message="`Parece que has excedido el límite de ${maxLength} caracteres por ${valueDisplay.length - maxLength}. ¿Podrías reducirlo un poco?`"
        v-else-if="valueDisplay.length > maxLength"
      />
      <ErrorMessage
        message="El número de celular debe contener solo números."
        v-else-if="phone && isNaN(phone)"
      />
      <ErrorMessage
        message="Ingresa un correo electrónico válido: ejemplo@correo.com"
        v-else-if="!attributeisFocused && email && value && !isAValidEmail"
      />
    </div>
  </div>
</template>

<script>
import ErrorMessage from '../ErrorMessage.vue'
import validateEmail from '../../commons/mixins/utils'

export default {
  components: {
    ErrorMessage,
  },
  props: {
    label: String,
    value: [Number, String],
    missingErrorMessage: {
      type: String,
    },
    showEmptyInputError: {
      type: Boolean,
      default: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    isTextArea: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Inserte valor'
    },
    maxLength: {
      type: Number,
      default: 5000
    },
    password: {
      type: Boolean,
      default: false
    },
    email: {
      type: Boolean,
      default: false
    },
    number: {
      type: Boolean,
      default: false
    },
    phone: {
      type: Boolean,
      default: false
    },
    isForSearch: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showPassword: false,

      attributeIsMissing: false,
      attributeisFocused: false,

      isAValidEmail: true
    }
  },
  methods: {
    attributeFocused() {
      if (this.disabled) return
      this.attributeisFocused = true
    },
    attributeBlurred() {
      this.attributeisFocused = false
      this.attributeIsMissing = !this.value
      if(this.email) {
        this.isAValidEmail = validateEmail.methods.validateEmail(this.value)
      }
    },
    blurInput(event) {
      event.target.blur()
    }
  },
  computed: {
    valueDisplay: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    showMissingErrorMessage() {
      return this.missingErrorMessage && this.attributeIsMissing && !this.isOptional
    },
    inputType() {
      if(this.password) return 'password'
      if(this.email) return 'email'
      if(this.number) return 'number'
      if(this.phone) return 'tel'
      return 'text'
    }
  },
  created () {
    this.$watch('value', newValue => {
      if(newValue) {
        this.attributeIsMissing = false
      }
    })
    this.$watch('showEmptyInputError', value => {
      if(value) {
        this.attributeIsMissing = true
      }
    })
  },
}
</script>

<style scoped lang="scss">
.label-and-input {
  display: grid;
  gap: 8px;
  width: 100%;
}
.custom-input-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1a1a1a;
  text-align: left;
}
.input-select-group {
  margin-top: 0 !important;
  min-height: 48px;
  border: 1px solid #737373;
  border-radius: 12px;
  display: flex;
  align-items: center;

  .input-for-password {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .eye-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .4s;
      padding: 12px;
      margin: -12px 0 -12px -12px;
      border-radius: 100%;
      &:hover {
        background-color: rgba(26, 26, 26, 0.1);
      }
      &:active {
        background-color: rgba(26, 26, 26, 0.2);
      }
    }
  }
  .input-for-search {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 12px 16px;
    gap: 8px;
    .erase__text{
      cursor: pointer;
      display: flex;
      padding: 12px;
      margin: -12px;
      transition: .3s;
      border-radius: 50%;
      &:hover { background-color: rgba(26, 26, 26, 0.10) }
      &:active { background-color: rgba(26, 26, 26, 0.20) }
    }
    .input-primary {
      padding: 0 !important;
      height: auto !important;
      color: #1A1A1A;
      border-radius: 12px !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  input[readonly] { cursor: not-allowed; color: #1A1A1A !important }
}
.input-select-group:hover {
  border: 1px solid #1A1A1A;
}
.input-select-group.focused { border: 2px solid #1A1A1A }
.input-select-group.disabled { background: #EBEBEB }


.attributeIsMissing { border: 1px solid #E50000 }

.input-primary,
.input-primary:focus{
  border-bottom: none !important;
}
.input-primary {
  border-radius: 12px !important;
  padding: 1px 16px !important;
  margin-bottom: 0px!important;
}

.textarea-primary {
  padding: 12px 16px;
  resize: none;
  height: 72px;
  border: none;
}
.textarea-primary:focus {
  outline: none;
}

.input-primary::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #737373;
}
.input-primary::-webkit-inner-spin-button,.input-primary::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-primary {
  -moz-appearance: textfield;
}

.input-primary:not(.browser-default):focus:not([readonly]) {
  border-bottom: none !important;
  padding-bottom: 0px!important;
}

</style>